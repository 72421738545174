import React from "react"
import Layout from "../../components/layout"
import RecipeComponents from "../../components/content-pages/recipe-components"
import { graphql } from "gatsby"
import SEO from "../../components/seo"

const RecipeTemplate = ({ data, location }) => {
  const { recipe_title } = data.sanityRecipe
  return (
    <Layout>
      <SEO title={recipe_title} url={location.pathname} />
      <RecipeComponents data={data} />
    </Layout>
  )
}

export default RecipeTemplate

export const query = graphql`
  query($slug: String!, $articleTypeSlug: String!) {
    allSanityArticleType(
      filter: { slug: { current: { ne: $articleTypeSlug } } }
    ) {
      nodes {
        article_type_title
        slug {
          current
        }
      }
    }
    allSanityRecipe(
      filter: {
        recipe_meta_data: {
          article_type_ref: { slug: { current: { eq: $articleTypeSlug } } }
        }
      }
    ) {
      nodes {
        _id
        slug {
          current
        }
        title: recipe_title
        meta: recipe_meta_data {
          article_type_ref {
            article_type_title
          }
        }
        _type
        image: recipe_image_image {
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          video
        }
      }
    }
    sanityRecipe(slug: { current: { eq: $slug } }) {
      _type
      _key
      recipe_title
      recipe_content {
        ... on SanityVideoUrl {
          _key
          _type
          url
        }
        ... on SanityArticleSection {
          _type
          _key
          _rawSectionContent(resolveReferences: { maxDepth: 6 })
          section_aside
          section_title
        }
        ... on SanityImage {
          _key
          _type
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      recipe_image_image {
        _key
        image {
          _key
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        video
      }
      slug {
        current
      }
      article_related_items {
        ... on SanityArticle {
          _id
          _type
          slug {
            current
          }
          article_title
        }
        ... on SanityRecipe {
          _id
          _type
          recipe_title
          slug {
            current
          }
        }
        ... on SanityProduct {
          _id
          product_name
          _type
          product_image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          product_variants {
            variants {
              variant_info_price
            }
          }
          slug {
            current
          }
          product_collection {
            slug {
              current
            }
          }
        }
      }
    }
  }
`
